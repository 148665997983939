var arrayToSentence, editProcess, toggleVisibilities, citiesChecksDoneProcess, countriesChecksDoneProcess, setIntroDisplay, setDetailsThemConnectionDisplay, setElisionDisplay, setExpoEditDisplay, setExpoMtSectionDisplay, setAnalysisSectionDisplay, setThemeGroupDeleteDisplay, setExpoStSectionDisplay, filterCompositionList, buildThemeTypeOptions;

$(document).on('turbolinks:load', function(){
  $.rails.refreshCSRFTokens();

  $("a#pub-cities-edit").on("click", function(){
    editProcess("pub-cities", $(this));
  });

  $("a#pub-countries-edit").on("click", function() {
    editProcess("pub-countries", $(this));
  });

  $("a#comp-cities-edit").on("click", function(){
    editProcess("comp-cities", $(this));
  });

  $("a#comp-countries-edit").on("click", function(){
    editProcess("comp-countries", $(this));
  });
  $("a#comp-publishers-edit").on("click", function() {
    editProcess("comp-publishers", $(this));
  });

  $("a.pub-cities-done").on("click", function(){
    citiesChecksDoneProcess("pub");
  });

  $("a.pub-countries-done").on("click", function(){
    countriesChecksDoneProcess("pub");
  });

  $("a.comp-cities-done").on("click", function(){
    citiesChecksDoneProcess("comp");
  });

  $("a.comp-countries-done").on("click", function(){
    countriesChecksDoneProcess("comp");
  });

  $("a.comp-publishers-done").on("click", function(){
    doneProcess("comp-publishers");
  });

  $("select.premiere-city-select").on("change", function() {
    val = $(this).val();
    $option = $(this).find(`option[value="${val}"]`);
    $("select.premiere-country-select").val($option.data('country-id'))

  });
  //click event bound to body because the two elements after "click" get
  //re-rendered after AJAX form submission
  $("body").on("click", "a#edit-analysts, input[type='reset']#edit-analysts-cancel", function() {
    stem = $(this).data('target-stem');
    toggleVisibilities([$(`p[id^=${stem}]`), $(`div[id^=${stem}]`)]);
  });

  $("a#analysis-components-toggle").on('click', function() {
    $(this).css('display', 'none');
    $(`div#${$(this).data('target')}`).css('display', 'block')
  });

  $("body").on('click', "input[name='has-intro']", function() {
    setIntroDisplay(true);
  });
  $("body").on('click', "input[name='introduction[thematic_connection]']", function() {
    setDetailsThemConnectionDisplay();
  });

  $("body").on('click', "input[name='introduction[elided_ending]']", function() {
    setElisionDisplay();
  });

  $("body").on('click', 'a#save-intro', function(){
    $("a[href='#intro-analysis']").trigger("click");
    if (!$("div#expo-analysis").hasClass('in')) {
      $("a[href='#expo-analysis']").trigger("click");
    }
    $("div#intro-analysis-data div#intro-errors").css('display', 'none');
    $("div#intro-analysis-data .field_with_errors").removeClass('field_with_errors');
  });

  $("body").on("click", "a.add-interpretation", function() {
    term = $(this).attr('id').replace('add-', '').replace('-interpretation', '');
    restricted = term.split('-')[0];
    $(`a.restricted-edit[data-restricted-edit='${restricted}']`).addClass('disabled btn');
    if ($(`div#${term}-type`).length > 0) {
      $(`div#${term}-type`).removeClass('display-none');
    }
    if ($(`div#${term}-analysis`).length > 0) {
      $(`div#${term}-analysis`).removeClass('display-none');
      $(`div#${term}-form`).removeClass('display-none');
      $("html, body").animate({
        scrollTop: $(`div#${term}-form`).offset().top - 100
      }, 500);

    }
    $(`a#cancel-add-${term}-interpretation`).parent().removeClass('display-none');

  });

  $("body").on("click", "a.cancel-add-interpretation", function() {
    term = $(this).attr('id').replace('cancel-add-', '').replace('-interpretation', '');
    restricted = term.replace(/\-(mt|tr|st)/g, '');
    $(`a.restricted-edit[data-restricted-edit='${restricted}']`).removeClass('disabled').removeClass('btn');
    if ($(`div#${term}-type`).length > 0) {
      $(`div#${term}-type`).addClass('display-none');
    }
  });

  $("body").on('change','input[type="radio"][name*="-type"]', function(evt) {
    section = $(evt.target).attr('name').replace('-type', '');
    selector = `cancel-add-${section}-interpretation`
    $(`a#${selector}`).parent().addClass('display-none');
    setAnalysisSectionDisplay(section);
  });

  $("body").on("change", 'select[id*="_formal_type"], select[id*="_final_cadence"], select[id*="_internal_form"], select[id^="exposition_st_area_key"], select[id^="expo_st_group_st_module"], select[id^="expo_st_module"], select[id^="codetta"]', function(evt) {
    if (($(evt.target).find('option:checked').val() != "Other") && ($(evt.target).find('option:checked').val() != "Modulating")) {
      return;
    }
    if ($(evt.target).find('option:checked').val() == 'Other') {
      new_html = $("#add-other-formal-type").html().replaceAll('REPLACE', 'Other').replaceAll('Modulating', 'Other');
    }
    else if ($(evt.target).find('option:checked').val() == 'Modulating') {
      new_html = $("#add-other-formal-type").html().replaceAll('REPLACE', 'Modulating').replaceAll('Other', 'Modulating');
    }
    $("#add-other-formal-type").html(new_html);
    $("div#new-formal-type-description input[name='new-formal-type']").val($(evt.target).find('option:checked').val().replace(/Modulating(\: )?/, '').replace(/Other(\: )?/, '') );
    $("#add-other-formal-type").modal('show');
    $("div#new-formal-type-description").append($(`<input id="target-select" type="hidden" value="${$(evt.target).attr('id')}"/>`))

  });


  $("body").on('click', 'button#add-other-formal-type', function() {
    newType = `${$("input[type='text'][name='new-formal-type']").val()}`;

    if (newType != '') {
      $targetSelect = $(`select#${$("input#target-select").val()}`);
      $targetSelect.val("");
      if ($("#add-other-formal-type").text().includes("Modulating") ) {
        $targetSelect.find($("option[value='Modulating']")).before($(`<option value="Modulating: ${newType}" selected="selected">Modulating: ${newType}</option>`));
      } else { // otherwise it's "Other", but might need to add other cases if we expand on this pattern
        $targetSelect.find($("option[value='Other']")).before($(`<option value="Other: ${newType}" selected="selected">Other: ${newType}</option>`));
      }

      $("#add-other-formal-type").modal('hide');
      $("input[type='text'][name='new-formal-type']").val("");
      $("div#new-formal-type-description").find($("input[type='hidden']")).remove();
      $("div#new-formal-type-description p.alert").addClass('display-none');
    } else {
      $("div#new-formal-type-description p.alert").removeClass('display-none');
    }
  });

  $("body").on('click', '.add-other-formal-type-cancel', function() {
    $targetSelect = $(`select#${$("input#target-select").val()}`);
    $targetSelect.val($targetSelect.find('option[selected="selected"]').val());
    $("input[type='text'][name='new-formal-type']").val("");
    $("div#new-formal-type-description").find($("input[type='hidden']")).remove();
    $("div#new-formal-type-description p.alert").addClass('display-none');
  });

  $("body").on('click', 'table#compositions-full-index a.table-sort', function() {
    var sortSpan = $(this).parent().find('span.sort')
    var direction = sortSpan.hasClass('asc') ? 'desc' : 'asc';
    var otherDirection = direction == 'asc' ? 'desc' : 'asc';
    var filter = $(this).data('filter');
    var data = '';
    switch (filter) {
      case 'composers':
        data = 'composers';
        break;
      case 'titles':
        data = 'titles';
        break;
      case 'movement-types':
        data = 'movement-types';
        break;
      case 'genres':
        data = 'genres';
        break;
      case 'date-ranges':
        data = 'date-ranges';
        break;
      default:
        data = 'composers'
    }
    $.get('/admin/compositions.js', {field: data, direction: direction});

  });
  $("body").on('keyup', 'input[type="text"][class*="comp-filter-list"]', function() {
    filterCompositionList();
  });

  $(document).keyup(function(e) {
     if (e.key === "Escape") {
       $targetSelect = $(`select#${$("input#target-select").val()}`);
       $targetSelect.val($targetSelect.find('option[selected="selected"]').val());
       $("input[type='text'][name='new-formal-type']").val("");
       $("div#new-formal-type-description").find($("input[type='hidden']")).remove();
       $("div#new-formal-type-description p.alert").addClass('display-none');
    }
  });



  $("body").on('click', "a.restricted-edit, fieldset.restricted-edit", function() {
    if ($(this).hasClass('delete-link')) {
      //if no return in this case (i.e., don't disable all other restricted-edit links)
      //restrict-edit buttons remain disabled if user cancels delete
      //disabling others not necessary, anyhow, since the form immediately re-renders
      return;
    }
    restricted_edit = $(this).data('restricted-edit');
    $(`a.restricted-edit[data-restricted-edit*="${restricted_edit}"]`).addClass('disabled btn');
    if ($(this).prop('nodeName').toLowerCase() == 'a' && !/add\-(expo|recap)\-(mt|st)\-interpretation/.test($(this).attr("id"))) {
      $('fieldset.restricted-edit').attr('disabled',true)
    }
    $(`div${$(this).data('form-target')}`).removeClass('display-none');
    $(`div${$(this).data('parent-target')}`).addClass('display-none');
  });

  $("body").on('click', 'button.restricted-edit-cancel', function() {
    form_target = $(this).data('form-target');
    var whole = form_target.replace(/\-new\-form/g, '').replace(/\-[0-9]+\-form/g, '').replace("#", '').replace('-new', '');
    var stem = (whole == 'expo' || /codetta-[0-9]+/.test(whole)) ? whole : /[a-z]+\-(mt|tr|st|codetta)+/.exec(whole)[0];
    if (!stem.includes('_') ) {
      $(`input[name="${stem}-type"]`).prop('checked', false);
      setAnalysisSectionDisplay(stem);
      if ($(`a#add-${stem}-interpretation`).length == 1 && $(`div#${stem}-type`).length > 0) {
        $(`div#${stem}-type`).addClass('display-none');
      }
      if ($(`a#add-${form_target.replace('-form', '').replace("#", '')}-interpretation`).length > 0) {
        $(`div${form_target.replace('-form', '-analysis')}`).addClass('display-none');
      }
    }
    restricted_edit = $(this).data('restricted-edit');
    if (!$(this).hasClass('mt-grp')) {
      $(`.restricted-edit[data-restricted-edit*="${restricted_edit}"]`).removeClass('disabled btn');
      $('fieldset.restricted-edit').attr('disabled',false);
    } else {
      $(`.restricted-edit[data-restricted-edit*="${restricted_edit}"]`).filter('.mt-grp').removeClass('disabled btn');
    }
    $(`div${$(this).data('parent-target')}`).removeClass('display-none');
    $(`div${$(this).data('form-target')}`).addClass('display-none');

    error_div = form_target.includes('new-') ? form_target.replace('new-', '').replace('-form', '-new-errors') : form_target.replace('-form', '-errors')
    $(`div${error_div}`).html("");
  });

  $("body").on('click', "input[type='radio'][name^='mt_tr_blurring'], input[type='radio'][name^='tr'], input[type='radio'][name^='main_theme'], input[type='radio'][name*='key-area-count'], input[type='radio'][name^='st_'], input[type='radio'][name^='codetta']:not(input[type='radio'][name^='codetta_module']), input[type='radio'][name='exposition[is_three_key]']", function() {
    var change_target = $(this).parent().data('change-target');
    if (typeof change_target === 'undefined' || change_target === false) {
      return
    }
    the_field = /(first|second)/.exec($(this).attr('class'))[0];
    other_field = the_field == 'first' ? 'second' : 'first';
    if (!$(this).attr('name').includes('different_endings') ) {
      $(`div${$(this).parent().data('change-target')}`).find(`p[data-options-field='${the_field}']`).removeClass('display-none');
      $(`div${$(this).parent().data('change-target')}`).find(`p[data-options-field='${other_field}']`).addClass('display-none');
    } else {
      $(`div${$(this).parent().data('change-target')}`).find(`div[data-options-field='${the_field}']`).removeClass('display-none');
      //following if block addresses situation where someone changes from condition "No cadence" to something else and then back to "no cadence"
      //the handler for the onchange event of the ending selection (function on lines 363–378) hides all divs, so we have to undo that for the parent of the change target and siblings
      if ($(this).attr('name').includes('st')) {
        $(`div${$(this).parent().data('change-target')}`).find(`div[data-options-field='${the_field}']`).parent().removeClass('display-none');
        $(`div${$(this).parent().data('change-target')}`).find(`div[data-options-field='${the_field}']`).find('div').removeClass('display-none');
      }
      $(`div${$(this).parent().data('change-target')}`).find(`div[data-options-field='${other_field}']`).addClass('display-none');
      //next 4 lines declare variables that allow old JQuery to be generalized to work for both the ST Module and Codetta instantiations of the DOM elements
      var label_text_insert = $(this).attr('name').includes('st') ? 'ST module' : 'Codetta';
      var div_selector_insert = label_text_insert == 'ST module' ? 'st_' : 'codetta_';
      var label_for_insert = label_text_insert == 'ST module' ? 'st_module_st_' : 'codetta_';
      var label_text = the_field == 'first' ? "First ending only: has a RT (retransition)?" : `${label_text_insert} has a RT (retransition)?`
      //generalized to use variable in selector to get the right has_retrans div
      var has_retrans_div = $(this).parent().find(`div[data-action='update-${div_selector_insert}retrans-label']`);
      //generalized to use two variables in order to i) select the correct label ii) update the label with the right text for the situation
      has_retrans_div.find(`label[for='${label_for_insert}has_retrans']`).text(label_text);
      has_retrans_div.removeClass('display-none');
    }
    if ($(this).attr('name').includes('key-area-count') || $(this).attr('name').includes('is_three_key') ) {
      if (the_field == 'first') {
        console.log('here');
        $("span.modulating-in-first-key-block").removeClass('buttons-row').addClass('display-none');
      } else if (the_field == 'second'){
        $("span.modulating-in-first-key-block").removeClass('display-none').addClass('buttons-row');
      }
      var first_key_p = $(`div${$(this).parent().data('change-target')}`).find('p.first-key-area');
      var second_key_p = $(`div${$(this).parent().data('change-target')}`).find('p.second-key-area');
      if (first_key_p.hasClass('display-none')) {
        first_key_p.removeClass('display-none');
      }
      var only_one_key = ($(this).val() == 1 ) || $(this).val() == 'false'
      if (only_one_key) {
        first_key_p.find('label.first-key-area').text('ST key area');
        second_key_p.find('input:radio').prop("checked", false);
      } else {
        first_key_p.find('label.first-key-area').text('First ST key area');
      }
      $(`div${$(this).parent().data('change-target')}`).find(`p[data-options-field='${other_field}'] select`).val("");
    }

    if ($(this).attr('id').includes('link_to_dev') && $(this).val() == "false") {

      $(`div${$(this).parent().data('change-target')}`).find(`p[data-options-field='${other_field}'] select`).val("");
      if ($(this).val() == 'true') {
        $(`div${$(this).parent().data('change-target')}`).find(`p[data-options-field='${other_field}'] span`).addClass('display-none');
      }
    }
  });

  $("body").on("reset", 'form', function() {
    $(this).find('input[type="radio"][checked="checked"]').trigger('click');
  });

  $("body").on("change", 'input[name="st_module[theme_type_expanded]"]', function() {
    if ($(this).val() == 'true') {
      $(this).parent().find('span').removeClass('display-none').addClass('block');
    } else {
      $(this).parent().find('span').addClass('display-none').removeClass('block');
    }

  });

  $("body").on('change', 'select[name="transition[kind]"]', function() {
    section = $(this).attr('id').split('_')[0];
    var classReg = $(this).attr('id').replace('kind', 'internal-form-reg').replace(/_/g, '-');
    var class01 = $(this).attr('id').replace('kind', 'internal-form-01').replace(/_/g, '-');
    var class02 =$(this).attr('id').replace('kind', 'internal-form-02').replace(/_/g, '-');
    var class03 = $(this).attr('id').replace('kind', 'final-cadence-pt1').replace(/_/g, '-');
    if ($(this).val() == 'Two-part') {
      ($(`label.${classReg}`)).addClass('display-none');
      ($(`label.${class01}`)).removeClass('display-none');
      ($(`div.${class02}`)).removeClass('display-none');
      ($(`div.${class03}`)).removeClass('display-none');

    } else {
      ($(`label.${classReg}`)).removeClass('display-none');
      ($(`label.${class01}`)).addClass('display-none');
      ($(`div.${class02}`)).addClass('display-none');
      ($(`div.${class03}`)).addClass('display-none');
    }
  });

  $("body").on('change', 'p[id*="other-theme-type"] input[type="radio"]', function() {
    if ($(this).val() == 'Dissolving thematic statement') {

      $(this).parent().find('span').removeClass('display-none').addClass('block');
    } else {
      $(this).parent().find('span').addClass('display-none').removeClass('block');
    }
  });

/*========================================= st_group_ending ========================================= */
  $("body").on("change", 'input[name="st_module[is_last_module]"]', function () {
    if ($(this).val() == "true") {
      $(this).parent().find('div#expo-st-group-ending').removeClass('display-none').addClass('block');

      //these two lines show/hide a warnining about an imminent change to the saved alternative interptations (if any)
      $("div#st-module-alternative-interp-warning-is-last-yes").addClass('display-none');
      $("div#st-module-alternative-interp-warning-is-last-no").removeClass('display-none');
    } else {
      $('select[name="st_module[ending]"] option:selected').prop('selected', false);
      $('select[name="st_module[ending]"] option:first').prop('selected', 'selected');

      $('select[name="st_module[st_linking_strategy]"] option:selected').prop('selected', false);
      $('select[name="st_module[st_linking_strategy]"] option:first').prop('selected', 'selected');
      $(this).parent().find("div.radio-buttons input[type=radio]").prop('checked', false);
      //$(this).parent().find('input[type=radio][name$="st_module"]').prop('checked', false);
      //$(this).parent().find("div.radio-buttons div[id$=ending] div.radio-buttons").addClass('display-none');
      $(this).parent().find("div#expo-st-group-ending").addClass('display-none').removeClass('block');
      $(this).parent().find("p#expo-st-group-st-linking-strategies").addClass('display-none').removeClass('block');
      $(this).parent().find("div.blurred_boundary_display").addClass('display-none').removeClass('block');


      //these two lines show/hide a warning about an imminent change to the saved alternative interpretations (if any)
      $("div#st-module-alternative-interp-warning-is-last-no").addClass('display-none');
      $("div#st-module-alternative-interp-warning-is-last-yes").removeClass('display-none');
    }
  });

  $("body").on("click", "span#st-module-alternative-interpretation-warning-is-last-close", function(){
    $("div[id^=st-module-alternative-interp-warning-is-last]").addClass('display-none');
  });

  $("body").on("change", 'select[name="st_module[ending]"]', function () {
    if ($(this).val() == "ST module ends without a final cadence") {
      //two variables declared to determine state of Expo vis-a-vis is_repeated and has_codetta attributes
      var expoIsRepeated = $("div#expo-is-repeated-radios").find('input[type=radio]:checked').val() == 'true';
      var expoHasCodetta = $("div#expo-has-codetta-radios").find('input[type=radio]:checked').val() == 'true';
      //display the correct div based on state of exposition
      if (!expoHasCodetta && expoIsRepeated) {
          $(this).parent().find('div.different_endings_display').removeClass('display-none').addClass('block');
      }
      //display the correct div based on state of exposition
      if (!expoHasCodetta && !expoIsRepeated) {
        $(this).parent().find('div.blurred_boundary_display').removeClass('display-none').addClass('block');
      }

    } else {
      $(this).parent().find('input[type=radio]').prop('checked', false);
      $(this).parent().find('div.blurred_boundary_display div p[id$=linking-strategies]').addClass('display-none').removeClass('block');
      $(this).parent().find('div.blurred_boundary_display').addClass('display-none').removeClass('block');
      $(this).parent().find('div.different_endings_display').addClass('display-none').removeClass('block');
    }
  });

  $("body").on('click', '.radio-buttons-with-other input[type="radio"]', function() {
    if ($(this).attr('id').includes('other') || $(this).attr('id').includes('modulating')) {
      var id = $(this).attr('id') + '_filled';
      var text = $(`.radio-buttons-with-other input#${id}`)
      text.focus();
      var temp = text.val();
      text.val('');
      text.val(temp);
    }
  });

  $("body").on('click', '.radio-buttons-with-other input[type="text"]', function() {
    var id = $(this).attr('id');

    if (id.includes('_filled')) {
      $(`input[type="radio"]#${id.replace('_filled', '')}`).trigger('click');
    }
  });


  $("body").on('keyup', 'input[type="text"][class*="free-text-option"]', function() {
    var textEl = $(this);
    var id = textEl.attr('id').replace('_filled', '');
    var valueStem = id.includes('_other') ? 'Other: ' : 'Modulating: '
    var radioEl = textEl.parent().find(`input[type="radio"][id="${id}"]`);
    radioEl.val(valueStem + textEl.val())
  });

  setIntroDisplay(true);
  setDetailsThemConnectionDisplay();
  setElisionDisplay();
  setExpoEditDisplay();

  //observer to reset UI after partial page re-render
  if (/analyses\/\d+\/edit/g.test(window.location.href)) {
    var observedEl = document.querySelector('div#form-form');
    var observer = new MutationObserver(function(mutations) {
      setIntroDisplay(true);
      setDetailsThemConnectionDisplay();
      setElisionDisplay();
      setExpoEditDisplay();

    });
    var config = {childList: true, subtree: true}
    observer.observe(observedEl,config);
  }

  if (window.location.pathname == '/admin/compositions') {
    var observedEl = document.querySelector('div#compositions-table');
    var observer = new MutationObserver(function(mutations) {
      filterCompositionList()
    });
    var config = {childList: true, subtree: true}
    observer.observe(observedEl,config);
  }

});

/*========================================= function ========================================= */
arrayToSentence = function(array) {
  if (array.length == 0) {
    return "";
  }
  if (array.length == 1) {
    return array[0];
  } else if (array.length == 2) {
    return `${array[0]} and ${array[1]}`;
  } else {
    return array.slice(0, array.length - 1).join(', ') + ", and " + array.slice(-1);
  }
};

/*========================================= function ========================================= */

toggleVisibilities = function(elements) {
  elements.forEach(function($element) {
    newState = $element.css('display') == 'none' ? 'inline-block' : 'none';
    $element.css('display', newState);
  });
};

/*========================================= function ========================================= */

citiesChecksDoneProcess = function(stem) {
  $checkedCities = $(`div#${stem}-cities-checks`).find("input[type=checkbox]:checked");
  var cityLabels = []
  $checkedCities.each(function() {
    val = $(this).data('country-id');
    label = $(`label[for='${$(this).attr("id")}']`)
    labelText = (label.text() == "Unknown") ? label.text() + " " + label.parent().text().match(/in .*/) : label.text()
    cityLabels.push(labelText);
    $(`div#${stem}-countries-checks input[type=checkbox][value=${val}]`).prop("checked", "true");
  });
  var countryLabels = []
  $(`div#${stem}-countries-checks`).find("input[type=checkbox]:checked").each(function() {
    countryLabels.push($(`label[for='${$(this).attr("id")}']`).text());
  });
  $(`span#${stem}-cities-sentence`).text(arrayToSentence(cityLabels));
  $(`span#${stem}-countries-sentence`).text(arrayToSentence(countryLabels));
  doneProcess(`${stem}-cities`);
};

/*========================================= function ========================================= */

countriesChecksDoneProcess = function(stem) {
  $checkedCountries = $(`div#${stem}-countries-checks`).find("input[type=checkbox]:checked");
  var countryLabels = []
  $checkedCountries.each(function() {
    countryLabels.push($(`label[for='${$(this).attr("id")}']`).text());
  });
  $(`span#${stem}-countries-sentence`).text(arrayToSentence(countryLabels));
  doneProcess(`${stem}-countries`);
};

/*========================================= function ========================================= */

editProcess = function(stem, $element) {
  toggleVisibilities([$(`div#${stem}-checks`), $(`a.${stem}-done.hidden-manage-link`), $(`div#${stem} span.association-edit-disclaimer`), $element]);
  $(`div#${stem}`).addClass("uoft-border");
}

/*========================================= function ========================================= */

doneProcess = function(stem) {
  toggleVisibilities([$(`a#${stem}-edit`), $(`a.${stem}-done.hidden-manage-link`), $(`div#${stem} span.association-edit-disclaimer`), $(`div#${stem}-checks`)]);
  $(`div#${stem}`).removeClass("uoft-border");
}

/*========================================= function ========================================= */

setIntroDisplay = function(hide_save_button) {
  state = $("input[name='has-intro']:checked").val() == 'yes' ? 'inline-block' : 'none'
  opposite = state == 'inline-block' ? 'none' : 'inline-block'
  otherState = (typeof $("input[name='has-intro']:checked").val() === 'undefined') ? 'none' : opposite
  $("div#intro-analysis-data").css('display', state);
  $("p#save-intro").css('display', otherState);
  $("p#intro-section-head a.next-formal-section").css('display', otherState);

};

/*========================================= function ========================================= */

setDetailsThemConnectionDisplay = function() {
  state = $("input[name='introduction[thematic_connection]']:checked").val() == 'true' ? 'block' : 'none'
  $("div#details-thematic-connection").css('display', state)
};

/*========================================= function ========================================= */

setElisionDisplay = function() {
  elisionState = $("input[name='introduction[elided_ending]']:checked").val() == 'true' ? 'block' : 'none'
  nonElisionState = elisionState == 'block' ? 'none' : 'block'
  $("div#details-elision-kind").css('display', elisionState)
  $("div#details-non-elision-kind").css('display', nonElisionState)
};

/*========================================= function ========================================= */

setExpoEditDisplay = function() {
  setExpoMtSectionDisplay();


};

/*========================================= function ========================================= */

setExpoMtSectionDisplay = function() {
  singleMtState = $("input[name='expo-mt-type']:checked").val() == 'single-mt' ? 'block' : 'none';
  mtGroupState = $("input[name='expo-mt-type']:checked").val() == 'mt-group' ? 'block' : 'none';
  mtTrDissolutionState = $("input[name='expo-mt-type']:checked").val() == 'mt-dissolution' ? 'block' : 'none';
  mtTrFusionState = $("input[name='expo-mt-type']:checked").val() == 'mt-tr-fusion' ? 'block' : 'none';
  $("div#expo-new-mt-analysis").css('display', singleMtState);
  $("div#expo-new-mt-form").css('display', singleMtState);
  $("div#expo-new-mt-group-analysis").css('display', mtGroupState);
  $("div#expo-mt-group-new-form").css('display', mtGroupState);
  $("div#expo-new-mt-dissolution-analysis").css('display', mtTrDissolutionState);
  $("div#expo-new-mt-dissolution-form").css('display', mtTrDissolutionState);
  $("div#expo-new-mt-tr-fusion-analysis").css('display', mtTrFusionState);
  $("div#expo-new-mt-tr-fusion-form").css('display', mtTrFusionState);


};

/*========================================= function ========================================= */
setExpoTrSectionDisplay = function() {

 };

/*========================================= function ========================================= */

setAnalysisSectionDisplay = function(stem_in) {
  switch(stem_in) {
    case 'expo-mt':
      setExpoMtSectionDisplay();
      break;
    case 'expo-st':
      setExpoStSectionDisplay();
      break;
    default:
      setExpoMtSectionDisplay(); //and eventually all of them
  }
};

/*========================================= function ========================================= */
setThemeGroupDeleteDisplay = function(theme_group_type_in, main_section) {

  inserts = {
    'mt-group': ['-mt-group', 'new-mt-analysis'],
    'st-group': ['-st-group','new-st-analysis']
  }
  insert = inserts[theme_group_type_in];

  themeables = {
    'mt-group': 'main_themeable',
    'st-group': 'st_moduleable'
  }
  themeable = themeables[theme_group_type_in];
  $theme_group_div = $(`div[id^='${main_section + insert[0]}']`).filter($(`div[id*='${insert[1]}'`));
  if ($theme_group_div.length >= 1 && (($theme_group_div.css('display') != 'none') || (!$theme_group_div.hasClass('display-none') && ($theme_group_div.css('display') != 'none')))) {
    exempt_anchor_id = 'delete-saved-' + $theme_group_div.attr('id').replace(/\-group\-new\-(st|mt)\-analysis/g, '').split('-new')[0];
  }
};

/*========================================= function ========================================= */

setExpoStSectionDisplay = function() {
  singleStState = $("input[name='expo-st-type']:checked").val() == 'single-st' ? 'block' : 'none';
  stGroupState = $("input[name='expo-st-type']:checked").val() == 'st-group' ? 'block' : 'none';
  $("div#expo-new-st-module-analysis").css('display', singleStState);
  $("div#expo-new-st-module-form").css('display', singleStState);
  $("div#expo-new-st-group-analysis").css('display', stGroupState);
  $("div#expo-st-group-new-form").css('display', stGroupState);
};

/*========================================= function ========================================= */

filterCompositionList = function () {
  var input, filter, radio, filter_type, filter_class;
  inputs = $('input[type="text"][class*="comp-filter-list"]');
  var filters = {}
  inputs.each(function() {
    filters[`filter-${$(this).attr('id')}`] = $(this).val().toUpperCase();
  })
  filter_class = `filter-${$(this).attr('id')}`;
  $rows = $("table#compositions-full-index tbody tr");
  $rows.each(function() {
    composer = $(this).find(`td.filter-composers`);
    mvt_type = $(this).find(`td.filter-movement-types`);
    genre = $(this).find(`td.filter-genres`);
    date_range = $(this).find(`td.filter-date-ranges`);
    if ((composer.text().toUpperCase().includes(filters['filter-composers'])) &&
    (mvt_type.text().toUpperCase().includes(filters['filter-movement-types'])) &&
    (genre.text().toUpperCase().includes(filters['filter-genres'])) &&
    (date_range.text().toUpperCase().includes(filters['filter-date-ranges'])) ) {
      $(this).removeClass('display-none')
    } else {
      if (!$(this).hasClass('display-none')) {
        $(this).addClass('display-none')
      }
    }
  });
};

/*========================================= function ========================================= */


buildThemeTypeOptions = function(standardThemeType, $theSelect) {



};
